
                                    $static-content-cache-base-url: "https://s3-ap-southeast-2.amazonaws.com/prod-automait-public-website-content";
                                    $cloud-front-base-url: "https://dr1k2g3wmnols.cloudfront.net";
                                
//===================================================
@import '../../core';

//Theme Colours
$color-theme: #C3002F;
$color-theme--darker: darken($color-theme, 35%);
$color-theme-secondary: #920023;
$color-button: $color-theme;
$color-button--hover: $color-theme-secondary;
$color-button-text: $color-white;

//===================================================

// Text links
$link-color: $color-theme;

//Main nav themes
$nav-color: $color-theme;
$nav-mobile-color: $color-theme;
$nav-title-color: $color-black-500;
$nav-bg-color: $color-white;
$nav-br-color: $color-white;
$nav-active-br-color: $color-black-50;
$nav-toggle-bg-color: $color-white;
$nav-hover-color: $color-white;
$nav-hover-bg-color: $color-theme;
$nav-toggle-color: $color-theme;
$nav-logo: $static-content-cache-base-url + "/images/logos/brands/nissan-logo.svg";

//Contact Nav themes
$contact-nav-bg-color: $color-theme;
$contact-nav-color: $color-white;
$contact-nav-br-color: $color-theme;

//Sub Nav themes
$sub-nav-bg-color: $color-white;
$sub-nav-br-color: $color-black-50;
$sub-nav-hover-color: $color-theme;
$sub-nav-hover-bg-color: $color-black-50;
$sub-nav-title-color: $color-black-500;
$sub-nav-desc-color: $color-black-500;
$sub-nav-mobile-active-title-color: $color-theme;
$sub-nav-mobile-hover-title-color: $color-theme;

//Footer
$footer-bg-color: $color-black-1000;
$footer-link-hover-color: $color-theme;
$footer-link-underline-color: none;
$footer-mobile-hover-title-color: $color-theme;

//Colour for headers across the site (.header--theme-color)
$header-color: $color-black-500;

//Colours for cards
$card-header-color: $header-color;
$card-price-color: $header-color;
$card-icon-color: $header-color;
$card-link-color: $color-black-1000;
$card-hover-border-color: #88888888;
$card-hover-shadow-tint: #88888888;

//Colours for the Main header on content pages
$header-title-color: $color-black-500;
$header-sub-title-color: $color-black-500;
$header-gradient-color-left: #DDDDDD;
$header-gradient-color-right: #EEEEEE;

//Colours for the VDP page header
$header-VDP-title-color: $color-black-500;
$header-VDP-gradient-color-left: #FBFBFB;
$header-VDP-gradient-color-right: #ECECEC;

// Form/filter
$form-link-color: $color-theme-secondary;
$form-input-border-color: $color-black-200;
$form-svg-color: $color-black-1000;
$filter-item-click-color: $color-black-1000;
$filter-title-color: $color-black-1000;
$filter-title-bg-color: #E2E5E6;
$filter-title-hover-bg-color: darken($filter-title-bg-color, 5%);

// SVG icon colours (non-card)
$icon-bg-color: #EEEEEE;
$icon-fill-color: #C1C1C1;

//Colour for the sticky footer elements and VDP header
$sticky-footer-background-color: $color-black-800;
$form-header-color: $color-black-800;

// Panels
$panel-background-color: $color-theme--darker;

// Nissan Showroom specific styles
.section--showroom .text.richtext {
  text-align: center;
}

.section--showroom .col--6 .text.richtext {
  text-align: left;
}

@import "../../../src/application";